:root {
    --link-color: #dfa9a9;
}

* {
    padding: 0;
    margin: 0;
    box-sizing: border-box;
}

html, body {
    height: 100%;
    width: 100%;
    font-size: 14px;
    background-color: #000;
    color: #fff;
    font-family: Monospace, serif;
    line-height: 24px;
    overscroll-behavior: none;
    overflow: hidden;
    user-select: none; /* Standard syntax */
    -moz-user-select: none; /* Firefox */
    -webkit-user-select: none; /* Safari */
    -ms-user-select: none; /* IE 10 and IE 11 */
}

@media screen and (min-width: 320px) {
    html, body {
        font-size: calc(14px + 6 * ((100vw - 320px) / 680));
    }
}

@media screen and (min-width: 1000px) {
    html, body {
        font-size: 20px;
    }
}

#overlay {
    position: absolute;
    top: 0;
    left: 0;
    width: 100%;
    height: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    flex-direction: column;
    background: rgba(0, 0, 0, 1);
    z-index: 12;
}

#overlay button {
    background: transparent;
    border: 1px solid rgb(255, 255, 255);
    border-radius: 4px;
    color: #ffffff;
    padding: 12px 18px;
    font-variant: small-caps;
    cursor: pointer;
}

#overlay button:hover {
    border: 1px solid var(--link-color);
    color: var(--link-color);
}

a:link {
    color: var(--link-color);
}

a:visited {
    color: var(--link-color);
}

.top-nav {
    /*display: flex;*/
    /*justify-content: space-between;*/
    position: absolute;
    top: 0;
    padding: 12px;
    width: 100vw;
}

.top-nav button {
    float: left;
    color: #dfa9a9;
    background-color: inherit;
    padding: 8px 12px;
    width: 120px;
    border: 1px solid var(--link-color);
    border-radius: 4px;
    /*animation: color-change 10s infinite;*/
}

/*@keyframes color-change {*/
/*    0% {*/
/*        color: red;*/
/*    }*/
/*    50% {*/
/*        color: blue;*/
/*    }*/
/*    100% {*/
/*        color: red;*/
/*    }*/
/*}*/

.top-nav button:hover {
    cursor: pointer;
    color: whitesmoke;
    border: 1px solid whitesmoke;
}

form {
    font-weight: 300;
    /*margin: 30px 20px;*/
    transition: opacity .5s ease, transform .5s ease;
    height: 100vh;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-content: center;
    text-align: center;
}

.center {
    padding: 20px;
    text-align: center;
    margin-top: 10vh;
}

.form-styling {
    width: 100%;
    max-width: 576px;
    height: 35px;
    padding-left: 15px;
    border: none;
    border-radius: 20px;
    margin-bottom: 12px;
    background: rgba(255, 255, 255, .2);
    color: white;
}

#form-submit-button {
    width: 100%;
    max-width: 576px;
    height: 35px;
    border: whitesmoke solid 1px;
    border-radius: 20px;
    color: rgba(255, 255, 255, 1);
    background-color: rgba(122, 129, 141, 0.61);
    transition: all .5s ease, top .5s ease .5s, height .5s ease .5s, background-color .5s ease .75s;
}

#disabled-button {
    width: 100%;
    max-width: 576px;
    height: 35px;
    border: rgb(44, 44, 44) solid 1px;
    border-radius: 20px;
    color: rgb(44, 44, 44);
    background-color: rgba(122, 129, 141, 0.61);
}

#form-submit-button:hover {
    outline: whitesmoke solid 1px;
    cursor: pointer;
}

input[type="color"] {
    -webkit-appearance: none;
    -moz-appearance: none;
    border: none;
    border-radius: 8px;
}

input[type="color"]::-webkit-color-swatch-wrapper {
    padding: 0;
}

input[type="color"]::-webkit-color-swatch,
input[type="color"]::-moz-color-swatch {
    border: none;
}

input[name=color-picker-head] {
    width: 150px;
    height: 80px;
}

input[name=color-picker-body] {
    width: 85px;
    height: 90px;
}

.dot {
    height: 16px;
    width: 16px;
    background-color: #000000;
    border-radius: 50%;
    display: inline-block;
    margin-right: 8px;
    margin-left: 8px;
}

#eyes {
    position: relative;
    top: 50px;
}

div .flex-container {
    display: flex;
    padding: 10px;
    align-items: center;
    justify-content: space-around;
    flex-flow: row wrap;
}

div .grid-item {
    text-align: center;
    width: 250px;
}

div .paid {
    color: rgb(200, 200, 200)
}

div .unpaid {
    color: rgb(200, 200, 200)
}

@media all and ( max-width: 480px ) {
    div .flex-container {
        display: grid;
        grid-template-columns: repeat(2, auto);
    }

    div .grid-item {
        text-align: center;
        width: auto;
    }
}

.menu-overlay {
    height: 100%;
    width: 0;
    position: fixed;
    z-index: 1;
    left: 0;
    top: 0;
    overflow-x: hidden;
    background-color: rgb(146, 106, 106);
    transition: width 0.5s;
}

.menu-overlay div {
    padding: 4px 24px;
}

.menu-overlay h1, h2, h3, h4, h5, h6 {
    padding: 16px 20px 0 20px;
}

.menu-overlay a {
    color: #75aed3;
    text-decoration: none;
}

.menu-overlay a:hover, .overlay a:focus {
    color: #f1f1f1;
}

button, button::-moz-focus-inner {
    padding: 0;
    border: 0
}

.close {
    position: absolute;
    right: 32px;
    top: 32px;
    width: 32px;
    height: 32px;
    opacity: 0.5;
}

.close:hover {
    opacity: 1;
}
